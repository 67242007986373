import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { Picture } from 'react-responsive-picture'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
    width: 100%;
    ${({ theme }) => theme.mixins.flexColumn};

    @media ${({ theme }) => theme.media.laptop} {
        flex-direction: ${({ $imagesRight }) =>
            $imagesRight ? 'row' : 'row-reverse'};
        align-items: center;
    }
`

const TextWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexAlignCenterColumn};
    width: 100%;
    text-align: center;
    padding: 0 1rem 1rem 1rem;
    box-sizing: border-box;

    @media ${({ theme }) => theme.media.laptop} {
        padding-bottom: 0;
    }
`

const Title = styled.h2`
    font-size: 2rem;
    border-bottom: 1px solid black;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 2.4rem;
    }
`

const Info = styled.h2`
    padding: 1rem 0;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 1.8rem;
    }
`

const CTA = styled(Link)`
    font-size: 1.25rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 1.5rem;
    }
`

const ImageGrid = styled.div`
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
`

const ImageWrapper = styled(motion.div)`
    width: 50%;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        width: 25%;
    }

    @media ${({ theme }) => theme.media.laptop} {
        width: 50%;
    }
`

const Image = styled(Picture)`
    padding: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    height: calc(50vw - 1rem);
    object-fit: cover;
    object-position: center;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        height: calc(25vw - 0.5rem);
    }

    @media ${({ theme }) => theme.media.laptop} {
        height: 20vw;
    }
`

export {
    Wrapper,
    TextWrapper,
    Title,
    Info,
    CTA,
    ImageGrid,
    ImageWrapper,
    Image
}
