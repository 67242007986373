import styled, { css } from 'styled-components/macro'
import { motion } from 'framer-motion'

const CarouselWrapper = styled.div`
    width: 100%;
    ${({ theme }) => theme.mixins.flexAlignCenterColumn}
    margin-top: 1rem;
`

const ReviewWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexAlignCenter}
    margin: 0 0 1rem 0;
`

const Review = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    margin: 2rem 0;
`

const ReviewText = styled.em`
    padding: 0 0.25rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 1.1rem;
    }

    @media ${({ theme }) => theme.media.laptop} {
        font-size: 1.32rem;
    }
`

const ReviewDate = styled.p`
    margin-top: 0.5rem;
    text-align: right;

    @media ${({ theme }) => theme.media.laptop} {
        margin-top: 1rem;
        font-size: 1.2rem;
    }
`

const PipContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
`

const Pip = styled.div`
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 100%;
    opacity: ${({ theme }) => theme.opacity.disabled};
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows.small};

    ${({ $current, theme }) =>
        $current &&
        css`
            opacity: ${theme.opacity.full};
            box-shadow: ${theme.shadows.medium};
            ${theme.mixins.flexCenter};
            color: ${theme.colors.text};
        `}

    @media ${({ theme }) => theme.media.tabletPortrait} {
        width: 3rem;
        height: 3rem;
    }
`

const PipLabel = styled.p`
    font-size: 1rem;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.serif};

    @media ${({ theme }) => theme.media.laptop} {
        font-size: 1.2rem;
    }
`

export {
    CarouselWrapper,
    ReviewWrapper,
    Review,
    ReviewText,
    ReviewDate,
    PipContainer,
    Pip,
    PipLabel
}
