import styled, { css } from 'styled-components/macro'

const computeSideMargin = ($sides, size) =>
    $sides
        ? css`
              margin-left: 0;
              margin-right: 0;
          `
        : css`
              margin-left: ${size};
              margin-right: ${size};
          `

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${({ $top }) => ($top ? `0` : `2rem`)};
    margin-bottom: ${({ $bottom }) => ($bottom ? `0` : `2rem`)};
    ${({ $sides }) => computeSideMargin($sides, '2rem')}

    @media ${({ theme }) => theme.media.tabletPortrait} {
        ${({ $sides }) => computeSideMargin($sides, '4rem')}
    }

    @media ${({ theme }) => theme.media.tabletLandscape} {
        ${({ $sides }) => computeSideMargin($sides, '8rem')}
    }

    @media ${({ theme }) => theme.media.laptop} {
        margin-top: ${({ $top }) => ($top ? `0` : `4rem`)};
        margin-bottom: ${({ $bottom }) => ($bottom ? `0` : `4rem`)};
        ${({ $sides }) => computeSideMargin($sides, '12rem')}
    }
`

const Divider = styled.hr`
    width: ${({ $sides }) =>
        $sides ? `calc(100% - 8rem)` : `calc(100% - 4rem)`};
    border-color: ${({ theme }) => theme.colors.primaryDarkDisabled};
    margin: 4rem;

    @media ${({ theme }) => theme.media.tabletPortrait} {
        width: ${({ $sides }) =>
            $sides ? `calc(100% - 16rem)` : `calc(100% - 8rem)`};
    }

    @media ${({ theme }) => theme.media.tabletLandscape} {
        width: ${({ $sides }) =>
            $sides ? `calc(100% - 32rem)` : `calc(100% - 16rem)`};
    }

    @media ${({ theme }) => theme.media.laptop} {
        width: ${({ $sides }) =>
            $sides ? `calc(100% - 48rem)` : `calc(100% - 24rem)`};
        margin: 8rem;
    }
`

export { Container, Divider }
