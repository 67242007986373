import React from 'react'
import { InView } from 'react-intersection-observer'

import {
    Wrapper,
    TextWrapper,
    Title,
    Info,
    CTA,
    ImageGrid,
    ImageWrapper,
    Image
} from './ProductCard.styled'
import { scrollUp } from '../../functions/ScrollUp'
import { ProductImage } from '../../animation/animations'

const ProductCard = ({
    imagesRight,
    productInfo,
    productPage,
    title,
    sources,
    alts
}) => {
    return (
        <Wrapper $imagesRight={imagesRight}>
            <TextWrapper>
                <Title>{title}</Title>
                <Info>{productInfo}</Info>
                <CTA to={productPage} onClick={() => scrollUp(false)}>
                    {'Find out more'}
                </CTA>
            </TextWrapper>
            <InView triggerOnce threshold={0.25}>
                {({ inView, ref }) => (
                    <ImageGrid ref={ref}>
                        {sources.map((imageSet, index) => (
                            <ImageWrapper
                                key={index}
                                {...ProductImage(inView, index)}
                            >
                                <Image sources={imageSet} alt={alts[index]} />
                            </ImageWrapper>
                        ))}
                    </ImageGrid>
                )}
            </InView>
        </Wrapper>
    )
}

export { ProductCard }
