import { Theme } from '../../assets/styles/Theme'
const { media } = Theme
const { tabletPortrait, tabletLandscape, laptop } = media

const heroSources = [
    [
        {
            srcSet:
                './images/hero-images/full-width-white-shelving-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet: './images/hero-images/full-width-white-shelving-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/hero-images/full-width-white-shelving-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/hero-images/full-width-white-shelving-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/hero-images/full-width-white-shelving-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/hero-images/full-width-white-shelving-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/hero-images/full-width-white-shelving-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet: './images/hero-images/full-width-white-shelving-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet: './images/hero-images/double-white-wardrobe-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet: './images/hero-images/double-white-wardrobe-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet: './images/hero-images/double-white-wardrobe-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet: './images/hero-images/double-white-wardrobe-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet: './images/hero-images/double-white-wardrobe-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet: './images/hero-images/double-white-wardrobe-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet: './images/hero-images/double-white-wardrobe-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet: './images/hero-images/double-white-wardrobe-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet: './images/hero-images/white-unit-glass-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet: './images/hero-images/white-unit-glass-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet: './images/hero-images/white-unit-glass-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet: './images/hero-images/white-unit-glass-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet: './images/hero-images/white-unit-glass-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet: './images/hero-images/white-unit-glass-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet: './images/hero-images/white-unit-glass-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet: './images/hero-images/white-unit-glass-mobile.jpg',
            type: 'image/jpg'
        }
    ]
]

const bedroomSources = [
    [
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/bedroom/glass-wardrobes-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/bedroom/double-white-wardrobe-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/bedroom/large-painted-wardrobe-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/bedroom/white-wardrobe-two-drawers-mobile.jpg',
            type: 'image/jpg'
        }
    ]
]

const livingRoomSources = [
    [
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-white-unit-grating-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/livingroom/white-unit-glass-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/livingroom/large-green-unit-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/livingroom/full-width-white-shelving-mobile.jpg',
            type: 'image/jpg'
        }
    ]
]

const kitchenSources = [
    [
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-kitchen-windows-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/kitchen/medium-green-kitchen-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/kitchen/large-white-kitchen-mobile.jpg',
            type: 'image/jpg'
        }
    ],
    [
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-laptop.webp',
            type: 'image/webp',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-laptop.jpg',
            type: 'image/jpg',
            media: laptop
        },
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-landscape.webp',
            type: 'image/webp',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-landscape.jpg',
            type: 'image/jpg',
            media: tabletLandscape
        },
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-portrait.webp',
            type: 'image/webp',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-portrait.jpg',
            type: 'image/jpg',
            media: tabletPortrait
        },
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-mobile.webp',
            type: 'image/webp'
        },
        {
            srcSet:
                './images/product-card-images/kitchen/small-white-kitchen-with-bar-mobile.jpg',
            type: 'image/jpg'
        }
    ]
]

export { heroSources, bedroomSources, livingRoomSources, kitchenSources }
