import React from 'react'
import { Helmet } from 'react-helmet'

import { StructuredData } from '../../components/StructuredData/StructuredData'
import { Section } from '../../components/Section/Section'
import { TextSection } from '../../components/TextSection/TextSection'
import { Hero } from '../../components/Hero/Hero'
import { ProductCard } from '../../components/ProductCard/ProductCard'
import { ReviewCarousel } from '../../components/ReviewCarousel/ReviewCarousel'

import {
    heroSources,
    bedroomSources,
    livingRoomSources,
    kitchenSources
} from './Home.sources'
import { heroAlts, bedroomAlts, livingRoomAlts, kitchenAlts } from './Home.alts'

const Home = ({ reviews, title, description }) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <link
                    rel="canonical"
                    href={'https://www.vision-furniture.co.uk/'}
                />
                <meta name="description" content={description} />
                <script type="application/ld+json">{StructuredData}</script>
            </Helmet>

            <Section top={true} bottom={true} sides={true}>
                <Hero sources={heroSources} alts={heroAlts} />
            </Section>

            <Section top={true} bottom={true} sides={true}>
                <ProductCard
                    imagesRight={true}
                    title="Fitted Wardrobes"
                    productInfo="Designed, manufactured and installed by Mark and the team, our bespoke wardrobes can enhance the bedroom and maximise storage."
                    productPage="/bedroom/"
                    sources={bedroomSources}
                    alts={bedroomAlts}
                />
            </Section>

            <Section top={true} bottom={true} sides={true}>
                <ProductCard
                    imagesRight={false}
                    title="Bespoke Furniture"
                    productInfo="We can design &amp; create a wide range of bespoke, fitted living room furniture to improve your home."
                    productPage="/livingroom/"
                    sources={livingRoomSources}
                    alts={livingRoomAlts}
                />
            </Section>

            <Section top={true} bottom={true} sides={true}>
                <ProductCard
                    imagesRight={true}
                    title="Shaker Style Kitchens"
                    productInfo="Bespoke shaker style kitchens to modernise your space and breath new life into one of the busiest rooms in the home."
                    productPage="/kitchen/"
                    sources={kitchenSources}
                    alts={kitchenAlts}
                />
            </Section>

            <Section top={true} bottom={false}>
                <TextSection>Hear what our customers have to say</TextSection>
                <ReviewCarousel reviews={reviews} />
            </Section>
        </>
    )
}

export default Home
