import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import {
    CarouselWrapper,
    ReviewWrapper,
    Review,
    ReviewText,
    ReviewDate,
    PipContainer,
    Pip,
    PipLabel
} from './ReviewCarousel.styled'
import { InRightOutLeftLMedium } from '../../animation/animations'

const ReviewCarousel = ({ reviews }) => {
    const [currentReview, setCurrentReview] = useState(0)

    return (
        <CarouselWrapper>
            <ReviewWrapper>
                <AnimatePresence initial={false} exitBeforeEnter>
                    <Review {...InRightOutLeftLMedium} key={currentReview}>
                        <ReviewText>
                            {`"${reviews[currentReview].review}"`}
                        </ReviewText>
                        <ReviewDate>{`- ${reviews[currentReview].date}`}</ReviewDate>
                    </Review>
                </AnimatePresence>
            </ReviewWrapper>

            <PipContainer>
                {reviews.map((review, index) => {
                    return reviews[currentReview] === review ? (
                        <Pip key={index} $current={true}>
                            <PipLabel>vf</PipLabel>
                        </Pip>
                    ) : (
                        <Pip
                            key={index}
                            onClick={() => {
                                setCurrentReview(index)
                            }}
                        ></Pip>
                    )
                })}
            </PipContainer>
        </CarouselWrapper>
    )
}

export { ReviewCarousel }
