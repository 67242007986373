import React from 'react'

import { Divider, Container } from './Section.styled'

const Section = ({ children, top, bottom, sides, hashID }) => (
    <Container id={hashID} $top={top} $bottom={bottom} $sides={sides}>
        {children}
        {bottom && <Divider $sides={sides} />}
    </Container>
)

export { Section }
