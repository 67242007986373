import React from 'react'

import { Text } from './TextSection.styled'

const TextSection = ({ justify, children, paddingY }) => (
    <Text $paddingY={paddingY} $justify={justify}>
        {children}
    </Text>
)

export { TextSection }
