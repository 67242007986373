const StructuredData = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Vision Furniture',
    image:
        'https://www.vision-furniture.co.uk/images/header-logo/vision-furniture-logo-laptop.jpg',
    '@id': 'https://www.vision-furniture.co.uk/',
    url: 'https://www.vision-furniture.co.uk/',
    telephone: '+447458 182 566',
    priceRange: '££',
    areaServed: {
        '@type': 'GeoCircle',
        geoMidpoint: {
            '@type': 'GeoCoordinates',
            latitude: '51.278522204607775',
            longitude: '-0.8426747343476737'
        },
        geoRadius: "25000"
    },
    sameAs: [
        'https://www.instagram.com/vision-furniture',
        'https://www.checkatrade.com/trades/VisionFurniture'
    ]
})

export { StructuredData }
