import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { InView } from 'react-intersection-observer'

import {
    Wrapper,
    ImageWrapper,
    Image,
    ContentFrame,
    ContentWrapper,
    Text,
    CTA
} from './Hero.styled'
import { scrollUp } from '../../functions/ScrollUp'
import { InRightOutLeftLarge, HeroContent } from '../../animation/animations'

const Hero = ({ sources, alts }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    useEffect(() => {
        const imageTimer = setInterval(() => {
            currentImageIndex + 1 < sources.length
                ? setCurrentImageIndex(index => ++index)
                : setCurrentImageIndex(0)
        }, 8000)

        return () => clearInterval(imageTimer)
    }, [currentImageIndex, sources.length])

    return (
        <Wrapper>
            <AnimatePresence initial={false} exitBeforeEnter>
                <ImageWrapper key={currentImageIndex} {...InRightOutLeftLarge}>
                    <Image
                        sources={sources[currentImageIndex]}
                        alt={alts[currentImageIndex]}
                    />
                </ImageWrapper>
            </AnimatePresence>
            <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                    <ContentFrame ref={ref} {...HeroContent(inView)}>
                        <ContentWrapper>
                            <Text>{'bringing your home to life'}</Text>
                            <CTA
                                to="/contact-us/"
                                onClick={() => scrollUp(false)}
                            >
                                {'Get in touch today'}
                            </CTA>
                        </ContentWrapper>
                    </ContentFrame>
                )}
            </InView>
        </Wrapper>
    )
}

export { Hero }
