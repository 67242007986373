import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { Picture } from 'react-responsive-picture'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 90vh;
    ${({ theme }) => theme.mixins.flexAlignCenterColumn};
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.primary};
`

const ImageWrapper = styled(motion.div)``

const Image = styled(Picture)`
    height: 90vh;

    @media ${({ theme }) => theme.media.tabletLandscape} {
        object-fit: cover;
        min-width: 85vw;
    }
`

const ContentFrame = styled(motion.div)`
    width: 90%;
    height: 60vh;
    ${({ theme }) => theme.mixins.flexCenter};
    border: 0.5rem solid ${({ theme }) => theme.colors.primaryDark};
    padding: 1rem;
    box-sizing: border-box;
    position: absolute;
    top: 10vh;

    @media ${({ theme }) => theme.media.mobile} {
        width: 80%;
        height: 40vh;
        top: 20vh;
    }

    @media ${({ theme }) => theme.media.tabletPortrait} {
        height: 30vh;
        top: 25vh;
    }

    @media ${({ theme }) => theme.media.laptop} {
        width: 50%;
        height: 30vh;
        top: initial;
        bottom: 12.5vh;
        right: 12.5vh;
    }
`

const ContentWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexAlignCenterColumn};
    justify-content: space-evenly;
    background-color: ${({ theme }) => theme.colors.primaryDarkDimmed};
    padding: 1rem;
    text-align: center;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
`

const Text = styled.h1`
    color: ${({ theme }) => theme.colors.surface};

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 2.4rem;
    }
`

const CTA = styled(Link)`
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.surface};

    @media ${({ theme }) => theme.media.tabletPortrait} {
        font-size: 1.5rem;
    }
`

export { Wrapper, ImageWrapper, Image, ContentFrame, ContentWrapper, Text, CTA }
