const heroAlts = [
    'A large, wall to wall, white shelving unit displaying books, furniture, and a television',
    'A double wide wardrobe unit that has been fitted into the corner of a bedroom',
    'A large, white shelving unit with glass windows displaying ornaments and a television'
]

const bedroomAlts = [
    'A pair of glass panel fronted white wardrobes in a bedroom',
    'A large, floor to ceiling, white bedroom wardrobe unit',
    'A large, floor to ceiling, painted bedroom wardrobe unit',
    'A white bedroom wardrobe with two small drawers at the bottom'
]

const livingRoomAlts = [
    'A large, white media unit with bespoke grating displaying ornaments and a large television',
    'A large, white shelving unit with glass windows displaying ornaments and a television',
    'A large green media unit that runs wall to wall',
    'A large, wall to wall, white shelving unit displaying books, furniture, and a television'
]

const kitchenAlts = [
    'A large, white kitchen with lots of natural light and counter top space',
    'A large kitchen with green cabinets and a big white rug in the center',
    'A long white kitchen with wooden flooring and spot lighting',
    'A small white kitchen with bar stools tucked under the end of the counter'
]

export { heroAlts, bedroomAlts, livingRoomAlts, kitchenAlts }
